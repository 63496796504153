<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card title="Work Order">
          <b-container>
            <b-row>
              <b-col cols="12">
                <qrcode-stream v-if="scanQrCode" :track="paintBoundingBox" @decode="onDecode"></qrcode-stream>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-btn @click="scanQrCode = true">
                  Scan QR
                </b-btn>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream
  },
  data() {
    return {
      scanQrCode: false,
    }
  },
  methods: {
    onDecode(decodeData) {
      document.location.href = decodeData;
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },
  }
}
</script>
